import { getPayAsYouGoSellingPlan, money, percentage } from '../utils';
import { ShopifyProductEntity, ShopifySellingPlanAllocationsEntity, ShopifySellingPlansEntity } from '../types/shopify';
import { ProductPlanEntity } from '../types/productPlan';

export const isPrepaidAllocation = (allocation: ShopifySellingPlanAllocationsEntity) =>
  Array.isArray(allocation.selling_plan?.options) &&
  allocation.selling_plan?.options.some(option => option?.name === 'Shipment amount');

export const getPrepaidShipmentsNumberFromOptions = options => {
  if (options && options.length > 1) {
    const shipmentAmountArray = options.find(option => option?.name === 'Shipment amount').value.split(' ');
    return shipmentAmountArray.length > 0 ? +shipmentAmountArray[0] : null;
  }

  return null;
};

export const getAllocationFrequency = (allocation: ShopifySellingPlanAllocationsEntity) => {
  // now frequency every_period will match with selling_plan_id so no need to convert it
  return (allocation.selling_plan_id || (allocation.selling_plan?.id ?? '')).toString();
};

export const getAllocationRegularPrice = (allocation: ShopifySellingPlanAllocationsEntity, currency: string) => {
  return money(allocation.compare_at_price, currency);
};

export const getAllocationSubscriptionPrice = (allocation: ShopifySellingPlanAllocationsEntity, currency: string) => {
  if (isPrepaidAllocation(allocation)) {
    const prepaidShipmentsPerBilling = getPrepaidShipmentsNumberFromOptions(allocation.selling_plan?.options);
    const pricePerShipment = Math.round(allocation.price / prepaidShipmentsPerBilling);
    return money(pricePerShipment, currency);
  }

  return money(allocation.price, currency);
};

const getPrepaidPercentage = (allocation: ShopifySellingPlanAllocationsEntity, pricePerShipment: number) => {
  return Math.round(((allocation.compare_at_price - pricePerShipment) * 100) / allocation.compare_at_price);
};

export const getAllocationDiscountRate = (allocation: ShopifySellingPlanAllocationsEntity, currency: string) => {
  if (isPrepaidAllocation(allocation)) {
    const prepaidShipmentsPerBilling = getPrepaidShipmentsNumberFromOptions(allocation.selling_plan?.options);
    const pricePerShipment = allocation.price / prepaidShipmentsPerBilling;
    const prepaidPercentageSavings = getPrepaidPercentage(allocation, pricePerShipment);

    return percentage(prepaidPercentageSavings);
  }

  let formatted_discount = '';

  if (allocation.price_adjustments[0]?.value_type === 'percentage') {
    formatted_discount = percentage(allocation.price_adjustments[0].value);
  } else if (allocation.price_adjustments[0]?.value) {
    formatted_discount = money(allocation.price_adjustments[0].value, currency);
  } else if (allocation.compare_at_price) {
    formatted_discount = money(allocation.compare_at_price - allocation.price, currency);
  }

  return formatted_discount;
};

export const getAllocationNumberOfShipments = (allocation: ShopifySellingPlanAllocationsEntity) => {
  if (isPrepaidAllocation(allocation)) {
    return getPrepaidShipmentsNumberFromOptions(allocation.selling_plan?.options);
  }
  return null;
};

export const addPrepaidPriceAndSavings = (
  allocation: ShopifySellingPlanAllocationsEntity,
  productPlan: ProductPlanEntity,
  payAsYouGoPlan: ShopifySellingPlansEntity,
  currency: string
) => {
  const prepaidShipmentsPerBilling = getPrepaidShipmentsNumberFromOptions(allocation.selling_plan?.options);
  const pricePerShipment = allocation.price / prepaidShipmentsPerBilling;
  const prepaidSaving = allocation.compare_at_price - pricePerShipment;
  const prepaidPercentageSavings = getPrepaidPercentage(allocation, pricePerShipment);
  const payAsYouGoAdjustment = payAsYouGoPlan?.price_adjustments?.[0];
  const payAsYouGoPercentage =
    payAsYouGoAdjustment && payAsYouGoAdjustment.value_type === 'percentage' ? payAsYouGoAdjustment.value : null;

  productPlan['regularPrepaidPrice'] = money(allocation.price, currency);
  productPlan['prepaidSavingsPerShipment'] = money(Math.round(prepaidSaving), currency);
  productPlan['prepaidSavingsTotal'] = money(Math.round(prepaidSaving * prepaidShipmentsPerBilling), currency);

  if (payAsYouGoPercentage && prepaidPercentageSavings) {
    productPlan['prepaidExtraSavingsPercentage'] = percentage(prepaidPercentageSavings - payAsYouGoPercentage);
  }

  return productPlan;
};

export const mapSellingPlanToDiscount = (
  allocation: ShopifySellingPlanAllocationsEntity,
  sellingPlans: ShopifySellingPlansEntity[],
  currency: string
) => {
  if (!allocation.selling_plan) {
    allocation.selling_plan = sellingPlans.find(plan => plan.id === allocation.selling_plan_id);
  }

  const productPlan: ProductPlanEntity = {
    frequency: getAllocationFrequency(allocation),
    regularPrice: getAllocationRegularPrice(allocation, currency),
    subscriptionPrice: getAllocationSubscriptionPrice(allocation, currency),
    discountRate: getAllocationDiscountRate(allocation, currency),
    prepaidShipments: getAllocationNumberOfShipments(allocation)
  };

  if (isPrepaidAllocation(allocation)) {
    const payAsYouGoPlan = getPayAsYouGoSellingPlan(sellingPlans);
    return addPrepaidPriceAndSavings(allocation, productPlan, payAsYouGoPlan, currency);
  }

  return productPlan;
};

export const sellingPlanAllocationsReducer = (
  acc: ProductPlanEntity[],
  cur: ShopifySellingPlanAllocationsEntity,
  sellingPlans: ShopifySellingPlansEntity[] = [],
  currency: string
) => [...acc, mapSellingPlanToDiscount(cur, sellingPlans, currency)];

export const getSellingPlans = (product: ShopifyProductEntity) =>
  product.selling_plan_groups.reduce<ShopifySellingPlansEntity[]>(
    (allGroups, group) => [...allGroups, ...group.selling_plans.map(selling_plan => ({ ...selling_plan, group }))],
    []
  );
