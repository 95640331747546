import { LitElement, html } from 'lit-element';
import { connect } from '../core/connect';
import {
  makeProductPrepaidShipmentOptionsSelector,
  makeProductPrepaidShipmentsOptedInSelector,
  makePrepaidShipmentsSelectedSelector
} from '../core/selectors';
import { productChangePrepaidShipments } from '../core/actions';
import { withProduct } from '../core/resolveProperties';

export class PrepaidStatus extends withProduct(LitElement) {
  static get properties() {
    return {
      options: { type: Array },
      shipmentsOptedIn: { type: Number },
      prepaidShipmentsSelected: { type: Number },
      defaultPrepaidShipments: { type: Number, attribute: 'default-prepaid-shipments' }
    };
  }

  get prepaidOptedIn() {
    return this.shipmentsOptedIn > 1;
  }

  get selectedNumberOfShipments() {
    return this.prepaidShipmentsSelected || this.shipmentsOptedIn || this.getDefaultPrepaidShipments();
  }

  getDefaultPrepaidShipments() {
    return this.options.includes(this.defaultPrepaidShipments)
      ? this.defaultPrepaidShipments
      : this.options[1] || this.options[0];
  }

  handleSelect({ target: { value } }) {
    const valueAsNumber = +value;
    this.productChangePrepaidShipments(this.product, valueAsNumber, this.offer);
  }

  render() {
    return html``;
  }
}

export const mapStateToProps = (state, ownProps) => ({
  options: makeProductPrepaidShipmentOptionsSelector(ownProps.product.id)(state),
  shipmentsOptedIn: makeProductPrepaidShipmentsOptedInSelector(ownProps.product)(state),
  prepaidShipmentsSelected: makePrepaidShipmentsSelectedSelector(ownProps.product)(state)
});

export const ConnectedPrepaidStatus = connect(mapStateToProps, { productChangePrepaidShipments })(PrepaidStatus);

export default ConnectedPrepaidStatus;
