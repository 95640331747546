export const ogAuthRegExp = /^og_auth=/;

/**
 * Retrieve og_auth cookie from current document
 */
export const readAuthCookie = (_ogAuthRegExp = ogAuthRegExp) =>
  (document.cookie.split(/;\s*/).find(it => it.match(_ogAuthRegExp)) || '').replace(ogAuthRegExp, '');

/**
 * Converts a string authCookie representation into a hmac auth object
 * @param {*} authCookie
 */
export const parseAuth = authCookie => {
  // Called to set auth parameters if the config setting for HMAC auth is used
  if (typeof authCookie === 'object') return authCookie;
  const parts = String(authCookie || '').split('|');

  return parts.length === 3
    ? {
        sig_field: parts[0],
        ts: parseInt(parts[1], 10),
        sig: parts[2]
      }
    : null;
};

/**
 * Loads the auth url into and iframe and useful to detect cookies set by javascript
 * @param {*} auth_url
 */
export const iframeLoad = auth_url => {
  return new Promise((resolve, reject) => {
    const iframe = document.createElement('iframe');
    iframe.style.setProperty('display', 'none', 'important');
    document.body.appendChild(iframe);

    iframe.onload = resolve;
    iframe.onerror = reject;
    iframe.src = auth_url;
  });
};

/**
 * Detects if a fetch response is json
 * @see https://stackoverflow.com/questions/37121301/how-to-check-if-the-response-of-a-fetch-is-a-json-object-in-javascript
 * @param {} response
 */
export const isJsonResponse = response =>
  (response.headers.get('content-type') || '').indexOf('application/json') !== -1;

/**
 * Reads auth from window.og_auth
 * @returns {AuthObject|null} Returns null if auth fails or the object containing sig_field,
 *  ts and sig used to authorize with ordergroove
 */
function _readStaticAuth() {
  if (typeof window.og_auth !== 'undefined') {
    return parseAuth(window.og_auth);
  }
  return null;
}
/**
 * Waits 100ms to read window.og_auth value
 * @param {int} ms
 * @returns {Promise<AuthObject>} Returns a promise of AuthObject
 */
async function _delayedReadStaticAuth(ms = 100) {
  return new Promise(res => {
    setTimeout(() => res(_readStaticAuth()), ms);
  });
}

/**
 * Given a merchant auth endpoint this function tries to resolve the current auth.
 * If og_auth is in cookie it returns it, otherwise it call the merchant auth endpoint detecting
 * if response is json or cookie set by repsonse header and return it.
 *
 * @param {*} auth_url Merchant auth url endpoint
 * @param {*} _readAuthCookie method to read cookie (for test purpose)
 * @param {*} _iframeLoad method to load an iframe (for test purpose)
 */
export async function resolveAuth(auth_url, _readAuthCookie = readAuthCookie, _iframeLoad = iframeLoad) {
  let auth;

  auth = parseAuth(_readStaticAuth()) || parseAuth(_readAuthCookie());

  if (auth) {
    return auth;
  }

  if (auth_url && typeof auth_url === 'string') {
    const response = await fetch(auth_url);
    // https://github.com/github/fetch/issues/386#issuecomment-243145797
    // detect if cookie was written by latest request
    if (response.status >= 200 && response.status < 300) {
      auth =
        _readAuthCookie() ||
        (await (isJsonResponse(response)
          ? response.json()
          : Promise.resolve(_iframeLoad(auth_url)).then(_readAuthCookie)));
    }
  } else if (!auth) {
    // If there is no auth_url and no auth at this point
    // lets wait for 100 ms to see if window.og_auth is set via js on DOM
    auth = await _delayedReadStaticAuth();
  }

  auth = parseAuth(auth);
  if (auth) return auth;
  throw new Error('Unauthorized');
}

export default resolveAuth;
