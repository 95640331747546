import { html } from 'lit-element';
import { optinProduct } from '../core/actions';
import { OptinButton } from './OptinButton';
import { mapStateToProps } from './OptinStatus';
import { connect } from '../core/connect';
import { resolveProduct } from '../core/resolveProperties';

export class SubscriptionButton extends OptinButton {
  /*
   * The main difference from this SubscriptionButton from the OptinButton is that
   * this button is disabled when the prepaid shipments are enabled.
   *
   * It has prepaid information so it can work together with the PrepaidButton.
   */

  static get properties() {
    return {
      ...super.properties,
      prepaidShipmentsOptedIn: { type: Number }
    };
  }

  get isActive() {
    if (this.prepaidShipmentsOptedIn > 0) {
      return false;
    }
    return this.subscribed;
  }

  handleClick(ev) {
    if (!this.isActive) {
      /*
        By using the `frequencies` we won't need to use the `defaultFrequency` property from the offer element.
        This is because the `frequencies` property will always evaluate to pay as you go frequencies, where the defaultFrequency
        could be a prepaid frequency if there are positive prepaidShipmentsOptedIn.
      */
      const payAsYouGoFrequency =
        this.frequencies && this.frequencies.length > 0 ? this.frequencies[0] : this.optinFrequency;
      this.optinProduct(resolveProduct(this), payAsYouGoFrequency, this.offer);
    }
    ev.preventDefault();
  }

  render() {
    return html`
      <slot name="default">
        <button id="action-trigger" class="btn radio ${this.isActive ? ' active' : ''}"></button>
        <label for="action-trigger">
          <slot>
            <slot name="label"><og-text key="offerOptInLabel"></og-text></slot>
          </slot>
        </label>
      </slot>
    `;
  }
}

export const ConnectedSubscriptionButton = connect(mapStateToProps, { optinProduct })(SubscriptionButton);

export default ConnectedSubscriptionButton;
