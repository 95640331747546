import { receiveOffer, receiveOrders, authorize, unauthorized, optinProduct } from './actions';
import { getObjectStructuredProductPlans } from './adapters';
import * as constants from './constants';

export const setPreviewStandardOffer = (isPreview, productId, offer) =>
  async function setPreviewStandardOfferThunk(dispatch) {
    await dispatch({
      type: constants.SET_PREVIEW_STANDARD_OFFER,
      payload: { isPreview, productId }
    });
    await dispatch({
      type: constants.UNAUTHORIZED
    });
    await dispatch(
      receiveOffer(
        {
          in_stock: { [productId]: true },
          eligibility_groups: { [productId]: ['subscription', 'upsell'] },
          result: 'success',
          autoship: { [productId]: true },
          autoship_by_default: { [productId]: false },
          modifiers: {},
          module_view: { regular: '096135e6650111e9a444bc764e106cf4' },
          incentives_display: {
            '47c01e9aacbe40389b5c7325d79091aa': {
              field: 'sub_total',
              object: 'order',
              type: 'Discount Percent',
              value: 5
            },
            e6534b9d877f41e586c37b7d8abc3a58: {
              field: 'total_price',
              object: 'item',
              type: 'Discount Percent',
              value: 10
            },
            f35e842710b24929922db4a529eecd40: {
              field: 'total_price',
              object: 'item',
              type: 'Discount Percent',
              value: 10
            },
            '5be321d7c17f4e18a757212b9a20bfcc': {
              field: 'total_price',
              object: 'item',
              type: 'Discount Percent',
              value: 1
            }
          },
          incentives: {
            [productId]: {
              initial: ['5be321d7c17f4e18a757212b9a20bfcc'],
              ongoing: [
                'e6534b9d877f41e586c37b7d8abc3a58',
                '47c01e9aacbe40389b5c7325d79091aa',
                'f35e842710b24929922db4a529eecd40'
              ]
            }
          }
        },
        offer,
        productId
      )
    );
  };

export const mergeProductPlansToState = (state, newProductPlans) => {
  Object.entries(newProductPlans).forEach(([key, value]) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      const mergedArray = state[key].concat(value);
      const uniqueArray = [...new Set(mergedArray.map(item => JSON.stringify(item)))];
      state[key] = uniqueArray.map(item => JSON.parse(item));
    } else {
      state[key] = value;
    }
  });
  return state;
};

export const setPreviewUpsellOffer = (isPreview, productId, offer) =>
  async function setPreviewUpsellOfferThunk(dispatch, getState) {
    await dispatch({ type: constants.SET_PREVIEW_UPSELL_OFFER, payload: { isPreview, productId } });

    const { merchantId } = getState();
    if (isPreview) {
      await dispatch(
        receiveOffer(
          {
            in_stock: { [productId]: true },
            module_view: { regular: '096135e6650111e9a444bc764e106cf4' },
            default_frequencies: { [productId]: { every: 1, every_period: 3 } },
            eligibility_groups: { [productId]: ['subscription', 'upsell'] },
            result: 'success',
            autoship: { [productId]: true },
            autoship_by_default: { [productId]: false },
            modifiers: {}
          },
          offer,
          productId
        )
      );
      await dispatch(
        receiveOrders({
          count: 1,
          next: null,
          previous: null,
          results: [
            {
              merchant: '0e5de2bedc5e11e3a2e4bc764e106cf4',
              customer: 'TestCust',
              payment: 'e98e789aba0111e9b90fbc764e107990',
              shipping_address: 'b3a5816ae59611e78937bc764e1043b0',
              public_id: '23322d4a83eb11ea9a1ebc764e101db1',
              sub_total: '206.98',
              tax_total: '0.00',
              shipping_total: '10.00',
              discount_total: '0.00',
              total: '216.98',
              created: '2020-04-21 11:14:11',
              place: '2020-06-24 00:00:00',
              cancelled: null,
              tries: 0,
              generic_error_count: 0,
              status: 1,
              type: 1,
              order_merchant_id: null,
              rejected_message: null,
              extra_data: null,
              locked: false,
              oos_free_shipping: false
            }
          ]
        })
      );
      await dispatch(authorize(merchantId, 'sig_field', 'ts', 'sig'));
    } else {
      await dispatch(unauthorized());
    }
  };

export const setPreviewPrepaid = (isPreview, productId, offer) =>
  async function setPreviewPrepaidThunk(dispatch, getState) {
    const existingProductPlans = getState().productPlans;

    await dispatch({
      type: constants.SET_PREVIEW_PREPAID_OFFER,
      payload: { isPreview, productId }
    });
    await dispatch({
      type: constants.UNAUTHORIZED
    });
    await dispatch(
      receiveOffer(
        {
          in_stock: { [productId]: true },
          eligibility_groups: { [productId]: ['subscription', 'upsell', 'prepaid'] },
          result: 'success',
          autoship: { [productId]: true },
          autoship_by_default: { [productId]: false },
          modifiers: {},
          module_view: { regular: '096135e6650111e9a444bc764e106cf4' },
          incentives_display: {
            '47c01e9aacbe40389b5c7325d79091aa': {
              field: 'sub_total',
              object: 'order',
              type: 'Discount Percent',
              value: 5
            },
            e6534b9d877f41e586c37b7d8abc3a58: {
              field: 'total_price',
              object: 'item',
              type: 'Discount Percent',
              value: 10
            },
            f35e842710b24929922db4a529eecd40: {
              field: 'total_price',
              object: 'item',
              type: 'Discount Percent',
              value: 10
            },
            '5be321d7c17f4e18a757212b9a20bfcc': {
              field: 'total_price',
              object: 'item',
              type: 'Discount Percent',
              value: 1
            }
          },
          incentives: {
            [productId]: {
              initial: ['5be321d7c17f4e18a757212b9a20bfcc'],
              ongoing: [
                'e6534b9d877f41e586c37b7d8abc3a58',
                '47c01e9aacbe40389b5c7325d79091aa',
                'f35e842710b24929922db4a529eecd40'
              ]
            }
          }
        },
        offer,
        productId
      )
    );
    await dispatch({
      type: constants.RECEIVE_PRODUCT_PLANS,
      payload: mergeProductPlansToState(
        existingProductPlans,
        getObjectStructuredProductPlans({
          [productId]: [
            {
              frequency: '1_3',
              regularPrice: '$15.00',
              subscriptionPrice: '$12.00',
              discountRate: '25%',
              prepaidShipments: 3,
              regularPrepaidPrice: '$36.00',
              prepaidSavingsPerShipment: '$3.00',
              prepaidSavingsTotal: '$9.00',
              prepaidExtraSavingsPercentage: '10%'
            },
            {
              frequency: '1_3',
              regularPrice: '$15.00',
              subscriptionPrice: '$12.00',
              discountRate: '20%',
              prepaidShipments: 6,
              regularPrepaidPrice: '$72.00',
              prepaidSavingsPerShipment: '$3.00',
              prepaidSavingsTotal: '$18.00',
              prepaidExtraSavingsPercentage: '10%'
            },
            {
              frequency: '1_3',
              regularPrice: '$15.00',
              subscriptionPrice: '$12.00',
              discountRate: '20%',
              prepaidShipments: 12,
              regularPrepaidPrice: '$144.00',
              prepaidSavingsPerShipment: '$3.00',
              prepaidSavingsTotal: '$36.00',
              prepaidExtraSavingsPercentage: '10%'
            }
          ]
        })
      )
    });
    await dispatch({
      type: constants.SET_CONFIG,
      payload: {
        prepaidSellingPlans: {
          [productId]: [
            {
              numberShipments: 3,
              sellingPlan: '1_3'
            },
            {
              numberShipments: 6,
              sellingPlan: '1_3'
            },
            {
              numberShipments: 12,
              sellingPlan: '1_3'
            }
          ]
        }
      }
    });
  };

export const setPreview = (value, oldValue, offer) =>
  async function (dispatch, _getState) {
    await dispatch({ type: constants.LOCAL_STORAGE_CLEAR });
    await dispatch({
      type: constants.SET_PREVIEW_STANDARD_OFFER,
      payload: { isPreview: false, productId: offer.product.id }
    });
    await dispatch({
      type: constants.SET_PREVIEW_UPSELL_OFFER,
      payload: { isPreview: false, productId: offer.product.id }
    });

    switch (value) {
      case 'regular':
        dispatch(setPreviewStandardOffer(true, offer.product.id, offer));
        break;
      case 'upsell':
        dispatch(setPreviewUpsellOffer(true, offer.product.id, offer));
        break;
      case 'subscribed':
        dispatch(setPreviewStandardOffer(true, offer.product.id, offer));
        dispatch(optinProduct(offer.product, '2_2'));
        break;
      case 'prepaid':
        dispatch(setPreviewPrepaid(true, offer.product.id, offer));
        // Prepaid needs to be subscribed to appear
        dispatch(optinProduct(offer.product, '1_3'));
        break;
      default:
    }
  };
