import { html } from 'lit-element';
import { optinProduct } from '../core/actions';
import { OptinStatus, mapStateToProps } from './OptinStatus';
import { connect } from '../core/connect';
import { defaultFrequency } from '../core/props';
import { resolveProduct } from '../core/resolveProperties';
import { frequencyToSellingPlan } from '../core/utils';
import platform from '../platform';

export class OptinButton extends OptinStatus {
  static get properties() {
    return {
      ...super.properties,
      frequency: { type: String, reflect: true },
      defaultFrequency,
      optinButtonLabel: { type: String }
    };
  }

  updated(changed) {
    if (changed.has('subscribed') || changed.has('frequencies')) {
      if (platform.shopify_selling_plans && this.store) {
        let buttonFreq = this.getAttribute('default-frequency');
        buttonFreq = frequencyToSellingPlan(buttonFreq, this.productFrequencies);
        this.sellingPlanFreq = buttonFreq;
      }
      this.frequencyMatch = this.frequency === this.optinFrequency;
    }
  }

  get optinFrequency() {
    let freq;

    // use the attribute since this.defaultFrequency comes from mapStateToProps and contains more logic
    if (this.sellingPlanFreq) {
      freq = this.sellingPlanFreq;
    } else if (this.hasAttribute('default-frequency')) {
      freq = this.getAttribute('default-frequency');
    } else {
      freq = this.offer ? this.offer.defaultFrequency : this.defaultFrequency;
    }
    if (platform.shopify_selling_plans && this.store) {
      freq = frequencyToSellingPlan(freq, this.productFrequencies);
    }

    return freq;
  }

  handleClick(ev) {
    this.optinProduct(resolveProduct(this), this.optinFrequency, this.offer);
    ev.preventDefault();
  }

  render() {
    return html`
      <slot name="default">
        <button
          aria-labelledby="ogOfferOptInLabel"
          role="radio"
          aria-checked="${!!this.subscribed}"
          class="btn radio ${this.subscribed ? 'active' : ''}"
        ></button>
        <label id="ogOfferOptInLabel">
          <slot>
            <slot name="label"><og-text key="offerOptInLabel"></og-text></slot>
          </slot>
        </label>
      </slot>
    `;
  }
}

export const ConnectedOptinButton = connect(mapStateToProps, { optinProduct })(OptinButton);

export default ConnectedOptinButton;
