import { LitElement, html } from 'lit-element';
import { connect } from '../core/connect';
import { withOfferTemplate } from '../core/resolveProperties';

export const pluralize = (word, count) => `${word}${parseInt(count, 10) > 1 ? 's' : ''}`;

export class Text extends withOfferTemplate(LitElement) {
  static get properties() {
    return {
      pluralize: { type: Number },
      variant: { type: Number },
      i18n: { type: Object, attribute: false },
      locale: { type: Object, attribute: false },
      key: {
        type: String
      }
    };
  }

  createRenderRoot() {
    return this;
  }

  connectedCallback() {
    super.connectedCallback();
    this._textOverride = this.innerText.trim();
  }

  getText() {
    return this._textOverride ? this._textOverride : this.getPluralizedText(this.getVariantText(this.key));
  }

  getVariantText(key) {
    const i18n = {
      ...this.i18n,
      ...(this.offer && this.offer.locale)
    };
    const text = typeof i18n[key] !== 'undefined' ? i18n[key] : '';
    if (typeof this.variant === 'undefined') {
      return text;
    }

    return text[this.variant];
  }

  getPluralizedText(text) {
    if (typeof this.pluralize === 'undefined') {
      return text;
    }

    return text ? pluralize(text, this.pluralize) : text;
  }

  render() {
    return html`
      ${this.getText()}
    `;
  }
}

export const mapStateToProps = state => ({
  i18n: state.locale || {}
});

export const ConnectedText = connect(mapStateToProps)(Text);

export default ConnectedText;
