const validParts = {
  // %d	Day of the month as a decimal number (range 01 to 31).
  day: { day: '2-digit' },

  // %e	Day of the month as a decimal number (range 1 to 31).
  'day-numeric': { day: 'numeric' },

  // %a	Abbreviated name of the day of the week.
  'day-short': { weekday: 'short' },

  // %A	Full name of the day of the week.
  'day-long': { weekday: 'long' },

  // %m	Month as a decimal number (range 01 to 12).
  month: { month: '2-digit' },

  // %n	Month as a decimal number (range 1 to 12).
  'month-numeric': { month: 'numeric' },

  // %b	Abbreviated month name.
  'month-short': { month: 'short' },

  // %B	Full month name.
  'month-long': { month: 'long' },

  // %y	Year as a decimal number without a century (range 00 to 99).
  year: { year: '2-digit' },

  // %Y	Year as a decimal number including the century.
  'year-numeric': { year: 'numeric' }
};

export const parse = date => {
  if (date instanceof Date) return date;
  return new Date(Date.parse(date));
};

export const formatDate = (date, format) => {
  if (date instanceof Date) {
    return (format || '').toString().replace(/\{\{([-\w]+)\}\}/g, it => {
      const part = it.replace(/[{}]/g, '');
      const value = validParts[part];

      if (typeof value === 'undefined') {
        return part;
      }

      const formatter = new Intl.DateTimeFormat('en-us', value);
      const dateInParts = formatter.formatToParts(date);
      const [{ value: result }] = dateInParts;
      return result;
    });
  }

  return date;
};
