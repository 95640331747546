import { LitElement, html, css } from 'lit-element';

import * as actions from '../core/actions';

export class TestWizard extends LitElement {
  static get styles() {
    return css`
      :host {
        position: fixed;
        top: 5em;
        righit: 5em;
        background-color: rgba(255, 255, 255, 0.7);
        width: 400px;
        padding: 1em;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-shadow: 2px 2px 0 0 #000;
      }

      button {
        margin: 0 0.5em 0.5em;
        background-color: gray;
        color: white;
        border: 0;
        border-radius: 3px;
        cursor: pointer;
        padding: 0.5em;
      }

      button.primary {
        background-color: blue;
        padding: 1em;
        color: white;
        border: 0;
        border-radius: 3px;
      }

      button[disabled] {
        background-color: #777;
      }

      div {
        margin-bottom: 0.5em;
      }

      .message {
        margin-left: 0.5em;
        margin: 1em;
      }

      .success {
        color: green;
      }

      .error {
        color: red;
      }

      .warning {
        color: orange;
      }
      a {
        color: white;
      }
    `;
  }

  runTests() {
    this.results = [];
    this.disabled = true;
    this.requestUpdate();

    const offerElements = document.querySelectorAll('og-offer');
    offerElements.forEach(element => {
      const state = element.store.getState();
      const productAttribute = element.getAttribute('product');
      const locationAttribute = element.getAttribute('location');
      const result = {
        messages: this.getOfferAttributeMessages(productAttribute, locationAttribute).concat(
          this.getOfferRequestMessages(productAttribute, state)
        ),
        product: productAttribute
      };
      this.results.push(result);
    });
    this.testsRan = true;
    this.disabled = false;
    this.requestUpdate();
  }

  getOfferAttributeMessages(productAttribute, locationAttribute) {
    const messages = [];

    if (!productAttribute) {
      messages.push({
        name: 'Offer element found but missing product attribute',
        type: 'error'
      });
    }

    if (!locationAttribute) {
      messages.push({
        name: 'Offer element found but missing location attribute',
        type: 'warning'
      });
    }

    if (productAttribute && locationAttribute) {
      messages.push({
        name: 'Offer element found and properly tagged',
        type: 'success'
      });
    }

    return messages;
  }

  getOfferRequestMessages(productAttribute, state) {
    const inStock = state.inStock[productAttribute];
    const autoshipEligible = state.autoshipEligible[productAttribute];
    const messages = [];

    if (productAttribute && inStock === false) {
      messages.push({
        name: 'This product is marked as out of stock in the OG database',
        type: 'warning'
      });
    }

    if (productAttribute && autoshipEligible === false) {
      messages.push({
        name: 'This product is not eligible for autoship',
        type: 'warning'
      });
    }

    if (productAttribute && inStock === null && autoshipEligible === null) {
      messages.push({
        name: 'This product does not exist in our database',
        type: 'error'
      });
    }

    return messages;
  }

  resultsCodeBlock() {
    return this.results.length === 0
      ? html`
          <div class="message error">No offer element found on the page</div>
        `
      : this.results.map(
          (result, ix) => html`
            <div>For offer tag with product = "${result.product}"</div>
            ${result.messages.map(
              message => html`
                <div class="message ${message.type}">${message.name}</div>
              `
            )}
            <button @click=${this.toggleProductFlags(ix, {})}>Set inStock and eligible</button>
            <br />
            <button @click=${this.toggleProductFlags(ix, { inStock: false })}>Set to not inStock</button>
            <br />
            <button @click=${this.toggleProductFlags(ix, { autoship: false })}>Set to not eligible</button>
            <br />
            <button @click=${this.toggleProductFlags(ix, { autoship: false, inStock: false })}>
              Set to not eligible and not in stock
            </button>
            <br />
            <button @click=${this.toggleUpsellPreview(ix)}>Toggle upsell/regular in this offer</button>
            <br />
            <button @click=${this.toggleUpsellNextOrder(ix)}>upsell product is in next order</button>
            <br />
          `
        );
  }

  toggleUpsellPreview(ix) {
    return ev => {
      ev.preventDefault();
      const offer = document.querySelectorAll('og-offer')[ix];
      if (!offer.getAttribute('preview-upsell-offer')) {
        offer.setAttribute('preview-upsell-offer', true);
      } else {
        offer.removeAttribute('preview-upsell-offer');
      }
      this.runTests();
    };
  }

  toggleProductFlags(ix, { inStock = true, autoship = true, groups = ['subscription', 'upsell'] }) {
    return ev => {
      ev.preventDefault();
      const offer = document.querySelectorAll('og-offer')[ix];
      const productId = offer.product.id;
      offer.store.dispatch(
        actions.receiveOffer(
          {
            in_stock: { [productId]: inStock },
            eligibility_groups: { [productId]: groups },
            result: 'success',
            autoship: { [productId]: autoship },
            module_view: { regular: '58a01e9aacbe40389b5c7325d79091bb' },
            modifiers: {},
            incentives_display: {
              '47c01e9aacbe40389b5c7325d79091aa': {
                field: 'sub_total',
                object: 'order',
                type: 'Discount Percent',
                value: 5
              },
              e6534b9d877f41e586c37b7d8abc3a58: {
                field: 'total_price',
                object: 'item',
                type: 'Discount Percent',
                value: 5
              },
              f35e842710b24929922db4a529eecd40: {
                field: 'total_price',
                object: 'item',
                type: 'Discount Percent',
                value: 10
              },
              '5be321d7c17f4e18a757212b9a20bfcc': {
                field: 'total_price',
                object: 'item',
                type: 'Discount Percent',
                value: 1
              }
            },
            incentives: {
              [productId]: {
                initial: ['5be321d7c17f4e18a757212b9a20bfcc'],
                ongoing: [
                  'e6534b9d877f41e586c37b7d8abc3a58',
                  '47c01e9aacbe40389b5c7325d79091aa',
                  'f35e842710b24929922db4a529eecd40'
                ]
              }
            }
          },
          {},
          productId
        )
      );
      this.runTests();
    };
  }

  toggleUpsellNextOrder(ix) {
    return ev => {
      const offer = document.querySelectorAll('og-offer')[ix];
      const productId = offer.product.id;

      ev.preventDefault();
      offer.store.dispatch(
        actions.receiveItems({
          count: 1,
          next: null,
          previous: null,
          results: [
            {
              order: '24d50352579511ea806cbc764e100cfd',
              offer: null,
              subscription: '8a076b7a0ea011e7a5bcbc764e105eda',
              product: productId,
              components: [],
              quantity: 1,
              public_id: '24d6901e579511ea806cbc764e100cfd',
              product_attribute: null,
              price: '14.99',
              extra_cost: '0.00',
              total_price: '13.49',
              one_time: false,
              frozen: false,
              first_placed: null
            }
          ]
        })
      );
      this.runTests();
    };
  }

  render() {
    return html`
      <div>
        ${this.testsRan
          ? this.resultsCodeBlock()
          : html`
              <div>Click the button to run tests</div>
            `}
        <button ?disabled=${this.disabled} @click="${this.runTests.bind(this)}" class="primary">Run Test</button>
      </div>
    `;
  }
}

export default TestWizard;
