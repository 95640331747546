import { bindActionCreators } from 'redux';

let storeInstance = null;

const defaultMapDispatchToProps = dispatch => ({ dispatch });

export const resolveStore = _obj => {
  if (!storeInstance) throw new Error('Missing redux store.');
  return storeInstance;
};

export const createRecalcProps = (mapStateToProps, mapDispatchToProps) => obj => {
  const { getState, dispatch } = resolveStore(obj);
  const stateProps = mapStateToProps ? mapStateToProps(getState(), obj) : {};
  const dispatchProps = mapDispatchToProps(dispatch, obj);

  Object.assign(obj, stateProps, dispatchProps);
};

/**
 * TODO this component can be coded as regular connect function. Instead of making the component
 * tied to stateChanged connect can accept mapStateToProps?, mapDispatchToProps?
 */
export const connect =
  (mapStateToProps, mapDispatchToProps = defaultMapDispatchToProps) =>
  baseElement => {
    const preparedDispatch =
      typeof mapDispatchToProps === 'function'
        ? mapDispatchToProps
        : dispatch => bindActionCreators(mapDispatchToProps, dispatch);

    const recalcProps = createRecalcProps(mapStateToProps, preparedDispatch);

    return class extends baseElement {
      get store() {
        return storeInstance;
      }

      connectedCallback() {
        if (super.connectedCallback) {
          super.connectedCallback();
        }

        this._storeUnsubscribe = resolveStore(this).subscribe(() => recalcProps(this));
        recalcProps(this);
      }

      attributeChangedCallback(name, old, value) {
        if (super.attributeChangedCallback) super.attributeChangedCallback(name, old, value);
        if (this._storeUnsubscribe && old !== value) recalcProps(this);
      }

      disconnectedCallback() {
        this._storeUnsubscribe();
        if (super.disconnectedCallback) {
          super.disconnectedCallback();
        }
      }
    };
  };
/**
 * This api will change asap
 * @deprecated
 * @param {@} _store
 */
export const setStore = _store => {
  storeInstance = _store;
};
/**
 * Only for test purpose
 */
export const unsetStore = () => {
  storeInstance = undefined;
};

export default connect;
