import { createIsMessageAllowed } from './core/createIsMessageAllowed';

const allowedOrigin = [
  'https://rc3.ordergroove.com',
  'https://rc3.stg.ordergroove.com',
  'https://rc3-beta.stg.ordergroove.com',
  'http://localhost:3000',
  'http://localhost:3010',
  'http://0.0.0.0:3010',
  window.location.origin
];

const createBroadcastMessage = opener => (ogType, data) => {
  allowedOrigin.forEach(target => opener.postMessage({ ogType, ...data }, target));
};

export function offersLiveEditor(opener = window.opener, og = window.og) {
  const handleReady = ev => {
    const isMessageAllowed = createIsMessageAllowed(allowedOrigin);
    const broadcastMessage = createBroadcastMessage(ev.source);
    const options = ev.data.options || {};

    if (isMessageAllowed(ev)) {
      if (ev.data.ogType === 'READY') {
        let publicPath = window.OG_CDN_PUBLIC_PATH || './';
        if (publicPath.startsWith('//')) publicPath = window.location.protocol + publicPath;
        if (!publicPath.endsWith('/')) publicPath += '/';

        import(`${publicPath}client.js`).then(({ initializeClient }) => {
          initializeClient({ isMessageAllowed, broadcastMessage, options, og });
          window.removeEventListener('message', handleReady);
        });
      }
    }
  };

  if (opener && opener !== window) {
    window.addEventListener('message', handleReady);
    createBroadcastMessage(opener)('READY');
  }
}

export default offersLiveEditor;
