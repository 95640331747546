import { html, css } from 'lit-element';

import {
  makeProductPrepaidShipmentOptionsSelector,
  makeProductPrepaidShipmentsOptedInSelector,
  makePrepaidShipmentsSelectedSelector
} from '../core/selectors';
import { productChangePrepaidShipments } from '../core/actions';
import { connect } from '../core/connect';
import { PrepaidStatus } from './PrepaidStatus';

export class PrepaidButton extends PrepaidStatus {
  constructor() {
    super();
    this.addEventListener('click', this.handleClick.bind(this));
  }

  static get styles() {
    return css`
      :host {
        cursor: pointer;
        display: inline-block;
      }

      :host[hidden] {
        display: none;
      }

      .btn {
        position: relative;
        width: var(--og-radio-width, 1.4em);
        height: var(--og-radio-height, 1.4em);
        margin: var(--og-radio-margin, 0);
        padding: 0;
        border: 1px solid var(--og-primary-color, var(--og-border-color, black));
        background: #fff;
        border-radius: 100%;
        vertical-align: middle;
        color: var(--og-primary-color, var(--og-btn-color, black));
      }

      .radio {
        text-indent: -9999px;
        flex-shrink: 0;
      }

      .radio {
        border-color: var(--og-checkbox-border-color, black);
      }

      .radio.active::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: var(--og-checkbox-border-color, black);
      }

      .radio.active::after {
        content: ' ';
        border-radius: 100%;
        border: 2px solid #fff;
      }
    `;
  }

  handleClick(ev) {
    if (!this.prepaidOptedIn) {
      this.productChangePrepaidShipments(this.product, this.selectedNumberOfShipments, this.offer);
    }
    ev.preventDefault();
  }

  render() {
    return html`
      <slot name="default">
        <button id="action-trigger" class="btn radio ${this.prepaidOptedIn ? 'active' : ''}"></button>
        <label for="action-trigger">
          <slot name="label"><og-text key="prepaidOptInLabel"></og-text></slot>
        </label>
      </slot>
    `;
  }
}

const mapStateToProps = (state, ownProps) => ({
  options: makeProductPrepaidShipmentOptionsSelector(ownProps.product.id)(state),
  shipmentsOptedIn: makeProductPrepaidShipmentsOptedInSelector(ownProps.product)(state),
  prepaidShipmentsSelected: makePrepaidShipmentsSelectedSelector(ownProps.product)(state)
});

export const ConnectedPrepaidButton = connect(mapStateToProps, { productChangePrepaidShipments })(PrepaidButton);

export default ConnectedPrepaidButton;
