import { LitElement, html } from 'lit-element';
import expression from 'logical-expression-parser';
import { connect } from '../core/connect';
import { withProduct } from '../core/resolveProperties';
import * as descriptors from '../core/descriptors';

export const removeWhitespace = str => str.replace(/(\r\n|\n|\r|\s)+/gm, '');

export class When extends withProduct(LitElement) {
  static get properties() {
    return {
      ...super.properties,
      state: { type: Object, attribute: false },
      test: { type: String }
    };
  }

  render() {
    if (!this.test) {
      return html``;
    }

    let test = removeWhitespace(this.test);
    // the parser returns incorrect result if you use an expression with &!, e.g. test1&!test2
    // it succeeds if you wrap !test2 in parentheses, e.g. test1&(!test2)
    // so we'll wrap all "not" expressions in parentheses
    test = test.replace(/(![a-zA-Z]+)/g, '($1)');
    const result = expression.parse(test, key => descriptors[key] && descriptors[key](this.state, this));

    if (result) {
      return html`
        <slot></slot>
      `;
    }
    return html``;
  }

  shouldUpdate(changedProperties) {
    return (
      changedProperties.size &&
      ((this.product && this.product.id in this.state.autoshipEligible && this.product.id in this.state.inStock) ||
        !this.product.id)
    );
  }
}

export const mapStateToProps = state => ({
  state
});

export const ConnectedWhen = connect(mapStateToProps)(When);

export default ConnectedWhen;
