import { html, css } from 'lit-element';

import { optinProduct, optoutProduct } from '../core/actions';
import { OptinStatus, mapStateToProps } from './OptinStatus';
import { connect } from '../core/connect';

export class OptinToggle extends OptinStatus {
  static get properties() {
    return {
      ...super.properties,
      frequency: { type: String }
    };
  }

  static get styles() {
    return css`
      :host {
        cursor: default;
        display: inline-block;
      }

      .btn {
        position: relative;
        width: var(--og-radio-width, 1.4em);
        height: var(--og-radio-height, 1.4em);
        margin: var(--og-radio-margin, 0);
        padding: 0;
        border: 1px solid var(--og-checkbox-border-color, black);
        background: #fff;
        vertical-align: middle;
        color: var(--og-primary-color, black);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
      }

      .btn.active {
        background: var(--og-checkbox-border-color, black);
      }

      .btn.active:after {
        content: '✓';
        color: #fff;
        transform: scale(1.6);
        margin-left: 2px;
      }
    `;
  }

  handleClick(ev) {
    if (this.subscribed) {
      this.optoutProduct(this.product, this.offer);
    } else {
      this.optinProduct(
        this.product,
        this.frequency || this.productDefaultFrequency || this.defaultFrequency,
        this.offer
      );
    }
    ev.preventDefault();
  }

  render() {
    return html`
      <slot name="default">
        <button id="action-trigger" class="btn checkbox ${this.subscribed ? 'active' : ''}"></button>
        <label for="action-trigger">
          <slot>
            <slot name="label"><og-text key="offerOptInLabel"></og-text></slot>
          </slot>
        </label>
      </slot>
    `;
  }
}

export const ConnectedOptinToggle = connect(mapStateToProps, { optoutProduct, optinProduct })(OptinToggle);

export default ConnectedOptinToggle;
