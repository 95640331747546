import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';
import { stringifyFrequency } from './api';
import platform from '../platform';
import { mapFrequencyToSellingPlan, safeProductId } from './utils';
import { OfferElement, ProductFrequencyConfig, State } from './types/reducer';

memoize.Cache = Map;

type BaseProduct = {
  id: string;
  components?: string[];
};

function arraysEqual<T>(a: T[], b: T[]) {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function resolveFrequency(sellingPlans: string[], frequenciesEveryPeriod: string[], frequency) {
  const ogFrequency = stringifyFrequency(frequency);
  if (!platform.shopify_selling_plans) return ogFrequency;
  return mapFrequencyToSellingPlan(sellingPlans, frequenciesEveryPeriod, ogFrequency);
}

export const isSameProduct = <T extends BaseProduct, S extends BaseProduct>(a: T, b: S) => {
  if ((a as BaseProduct) === b) return true;
  if (typeof a === 'object' && typeof b === 'object' && a && b) {
    if (a.id === b.id) {
      if (!(Array.isArray(a.components) && Array.isArray(b.components))) {
        return true;
      }
      if (arraysEqual((a.components || []).sort(), (b.components || []).sort())) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Returns a list of opted in products id from the state
 * @param {object} state
 */
export const optedinSelector = (state: State) => state.optedin || [];

const optedoutSelector = (state: State) => state.optedout || [];

export const autoshipSelector = (state: State) => state.autoshipByDefault || {};

const defaultFrequenciesSelector = (state: State) => state.defaultFrequencies || {};

const prepaidSellingPlansSelector = (state: State) => state?.config?.prepaidSellingPlans || [];
const prepaidShipmentsSelectedSelector = (state: State) => state?.prepaidShipmentsSelected || {};

/**
 * Creates a function with state arguments that return the true when
 * productId is in the optedin array or not in optedout or autoship by default
 */
export const makeOptedinSelector = memoize(
  (product: BaseProduct) =>
    createSelector(optedinSelector, optedoutSelector, autoshipSelector, (optedin, optedout, autoshipByDefault) => {
      const entry = optedin.find(b => isSameProduct(product, b));
      if (entry) {
        return entry;
      }
      if (optedout.find(b => isSameProduct(product, b))) {
        return false;
      }
      if (product && autoshipByDefault[product.id]) {
        return { id: product.id };
      }
      return false;
    }),
  product => JSON.stringify(product)
);
/**
 * Creates a function with state arguments that return the true when
 * productId is in the optedin array
 */
export const makeSubscribedSelector = memoize(
  (product: BaseProduct) =>
    createSelector(optedinSelector, optedin => {
      const entry = optedin.find(b => isSameProduct(product, b));
      if (entry) {
        return entry;
      }
      return false;
    }),
  product => JSON.stringify(product)
);

export const makePrepaidSubscribedSelector = memoize(
  (product: BaseProduct) =>
    createSelector(optedinSelector, optedin => optedin.some(b => isSameProduct(product, b) && b.prepaidShipments)),
  product => JSON.stringify(product)
);

export const makePrepaidShipmentsSelectedSelector = memoize(
  (product: BaseProduct) =>
    createSelector(
      prepaidShipmentsSelectedSelector,
      prepaidShipmentsSelected => prepaidShipmentsSelected[product.id] || null
    ),
  product => JSON.stringify(product)
);

/**
 * Creates a function with state arguments that return the true when
 * productId is in the optedout array
 */
export const makeOptedoutSelector = memoize((product: BaseProduct) =>
  createSelector(optedoutSelector, optedout => optedout.find(b => isSameProduct(product, b)))
);

export const makeProductFrequencyOptedInSelector = memoize(
  (product: BaseProduct) =>
    createSelector(
      makeOptedinSelector(product),
      productOptin => (productOptin && 'frequency' in productOptin && productOptin.frequency) || null
    ),
  product => JSON.stringify(product)
);

export const makeProductPrepaidShipmentsOptedInSelector = memoize(
  (product: BaseProduct) =>
    createSelector(
      makeOptedinSelector(product),
      productOptin => (productOptin && 'prepaidShipments' in productOptin && productOptin.prepaidShipments) || null
    ),
  product => JSON.stringify(product)
);

export const makeProductPrepaidShipmentOptionsSelector = memoize((productId: string) =>
  createSelector(prepaidSellingPlansSelector, prepaidSellingPlans => {
    const shipmentsList =
      prepaidSellingPlans[safeProductId(productId)]?.map(({ numberShipments }) => numberShipments) || [];
    return shipmentsList.sort((a, b) => a - b);
  })
);

/**
 * If the product has a product-specific default frequency configured in OG, return that frequency
 */
export const makeProductSpecificDefaultFrequencySelector = memoize((productId: string) =>
  createSelector(
    defaultFrequenciesSelector,
    makeProductFrequenciesSelector(productId),
    (defaultFrequencies, { frequencies: sellingPlans = [], frequenciesEveryPeriod = [] }) =>
      (defaultFrequencies[safeProductId(productId)] &&
        resolveFrequency(sellingPlans, frequenciesEveryPeriod, defaultFrequencies[safeProductId(productId)])) ||
      null
  )
);

export const makeProductFrequencyOptionsSelector = memoize((productId: string) =>
  createSelector(makeProductFrequenciesSelector(productId), productFrequencies => productFrequencies.frequencies)
);

/**
 * returns the default frequency for the product from the config state
 * all products have a defaultFrequency stored in state, even if a specific frequency is not configured in OG's database
 * this takes more into account, e.g. whether the customer had opted into a specific frequency previously - see the config reducer for how this is calculated
 */
export const makeProductDefaultFrequencySelector = memoize((productId: string) =>
  createSelector(makeProductFrequenciesSelector(productId), productFrequencies => productFrequencies.defaultFrequency)
);

/**
 * Get the configured frequencies for the given product IDs
 * Using this selector should be preferred over accessing config values directly
 */
export const makeProductFrequenciesSelector = memoize((productId: string) =>
  createSelector(
    (state: State) => state?.config?.productFrequencies,
    (state: State) => state?.config?.frequencies,
    (state: State) => state?.config?.frequenciesEveryPeriod,
    (state: State) => state?.config?.frequenciesText,
    (state: State) => state?.config?.defaultFrequency,
    (
      productFrequencies,
      oldFrequencies,
      oldFrequenciesEveryPeriod,
      oldFrequenciesText,
      oldDefaultFrequency
    ): ProductFrequencyConfig => {
      if (productFrequencies) {
        // for Shopify, always use productFrequencies
        // this is necessary to handle cases where different product variants have different selling plans associated with them
        return productFrequencies[safeProductId(productId)] || {};
      } else {
        // productFrequencies are only populated for Shopify
        // fall back to the old "global" frequency values if it is not set
        // these would only be present if the merchant explicitly called `offers.config({ frequencies: [...] })`, so they generally won't be defined
        return {
          frequencies: oldFrequencies,
          frequenciesEveryPeriod: oldFrequenciesEveryPeriod,
          frequenciesText: oldFrequenciesText,
          defaultFrequency: oldDefaultFrequency
        };
      }
    }
  )
);

// this selector is only called when an action is dispatched, so we don't need to memoize
// other selectors are called whenever the Redux state is updated
export const makeFrequencyForPrepaidShipmentsSelector = (product: BaseProduct, prepaidShipments: number) =>
  createSelector(
    prepaidSellingPlansSelector,
    makeProductFrequenciesSelector(product.id),
    (prepaidSellingPlans, { frequencies }) => {
      if (prepaidShipments) {
        const productId = safeProductId(product.id);
        const plan = prepaidSellingPlans[productId]?.find(p => p.numberShipments === prepaidShipments);
        return plan ? plan.sellingPlan : null;
      }
      return frequencies[0];
    }
  );

/**
 * Convert a string from camel case to kebab case.
 */
export const kebabCase = (string: string) => {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

export const getFallbackValue = (element: HTMLElement & { offer: OfferElement }, key: string, defaultValue?) =>
  (element && element.hasAttribute && element.hasAttribute(kebabCase(key)) && element[key]) ||
  (element.offer && typeof (element.offer[key] !== 'undefined') && element.offer[key]) ||
  defaultValue;

/**
 * Returns a list of opted in products id from the state
 */
export const templatesSelector = (state: State) => ({ templates: state.templates || [] });
