import { LitElement } from 'lit-element';

export const withTemplate = Base =>
  class extends Base {
    applyTemplate(template) {
      this.template = template;
      // update innerHTML if change (performance)
      const markup = typeof template.markup === 'undefined' ? this.constructor.initialTemplate : template.markup;
      if (markup && this._templateMarkup !== markup) {
        this._templateMarkup = markup;
        this.innerHTML = markup;
      }
    }

    refreshTemplate() {
      if (this._templates && this._templates.length) {
        // if offer is using templates.
        const tmpl = this._templates.find(({ selector }) => {
          try {
            return this.matches(selector);
          } catch (e) {
            return false;
          }
        });
        this.applyTemplate(tmpl || {});
      }
    }

    set templates(val) {
      this._templates = val;
      this.refreshTemplate();
    }

    connectedCallback() {
      if (super.connectedCallback) {
        super.connectedCallback();
      }
      if (this.constructor.initialTemplate && !this.innerHTML.trim()) {
        this.innerHTML = this.constructor.initialTemplate;
      }
    }
  };

export const TemplateElement = withTemplate(LitElement);

export default { TemplateElement };
