import { LitElement, html } from 'lit-element';
import { connect } from '../core/connect';
import { formatDate } from '../core/dateUtils';

export class FormattedDate extends LitElement {
  static get properties() {
    return {
      value: { type: String, reflect: true },
      format: { type: String }
    };
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${formatDate(this.value, this.format || '{{month-long}} {{day}}, {{year-numeric}}')}
    `;
  }
}

export const mapStateToProps = state => ({
  value: state.previewUpsellOffer ? new Date() : state.nextUpcomingOrder.place
});

export const ConnectedNextUpcomingOrder = connect(mapStateToProps)(FormattedDate);

export default FormattedDate;
