import { READY, RECEIVE_MERCHANT_SETTINGS, REQUEST_OFFER, SET_EXPERIMENT_VARIANT, SETUP_PRODUCT } from './constants';
import murmur from 'murmurhash-js';
import { waitFor } from './waitUntilOffersReady';
import { isExperimentSellingPlanGroup } from '../shopify/utils';

/**
 * Returns the index of a variant based on the provided key and variants.
 * The index is determined by the weighted distribution of the variants.
 *
 * @param {string} key - The key used to determine the variant index.
 * @param {object[]} variants - An array of objects containing the variant weights.
 * @return {number} The index of the selected variant.
 */
function getVariantIx(key, variants) {
  const weights = variants.map(variant => variant.weight);
  if (weights.reduce((a, b) => a + b, 0) !== 100) {
    console.error('OG: Sum of weights for variants must be 100. Defaulting to last variant.');
  }
  const m = murmur.murmur3(key, 0);
  const n = m % 100;

  let lower_bound = 0;

  for (let i = 0; i < variants.length; i++) {
    const v = variants[i];
    const upper_bound = lower_bound + v.weight;

    // If a variant has a weight of 0, ignore it
    if (v.weight > 0 && n < upper_bound) {
      return i;
    }

    lower_bound = upper_bound;
  }
  return variants.length - 1;
}

/**
 * Reduces the state of experiments based on the provided action.
 *
 * @param {object} state - The current state of experiments.
 * @param {object} action - The action to be applied to the state.
 * @return {object} The updated state of experiments.
 */
export function experimentsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_MERCHANT_SETTINGS:
      return { ...state, ...action.payload.experiments };

    case SET_EXPERIMENT_VARIANT:
      return {
        ...state,
        currentVariant: action.payload.index,
        offerProfileId: action.payload.parameters?.offer_profile_public_id
      };
    default:
      return state;
  }
}

/**
 * Resolve the Shopify product setup when in an experiment.
 * @param {object} variant - The experiment variant assigned to the customer.
 * @param {object} product - The Shopify product object.
 * @param {object} experimentSettings - The experiment settings object containing the public ID and variants.
 * @return {object} The modified product object with OG selling plan groups filtered out.
 *
 * This function filters a Shopify product's selling plan groups to only include the group
 * associated with the assigned experiment variant, and updates the product's variants to
 * only include selling plan allocations from that group. It returns the modified product
 * object.
 */
function resolveShopifySetupProductWhenExperiment(variant, product, experimentSettings) {
  // So, on the sellingPlanGroup appId we are doing "ordergroove-subscribe-and-save-{experiment_variant_id}"
  // this logic will only be enabled if we are in an experiment, there are at least 2 groups in the OG selling plan group
  if (!variant) return;

  if (experimentSettings.variants.length === 0) return;

  const sellingPlanGroups = product.selling_plan_groups.filter(isExperimentSellingPlanGroup);

  if (sellingPlanGroups.length !== experimentSettings.variants.length) return;

  const sellingPlanGroup = sellingPlanGroups.find(({ app_id }) => app_id.endsWith(variant.public_id));

  if (!sellingPlanGroup) return;

  return {
    ...product,
    selling_plan_groups: [sellingPlanGroup],

    variants: product.variants.map(({ selling_plan_allocations, ...it }) => ({
      ...it,
      selling_plan_allocations: selling_plan_allocations.filter(
        ({ selling_plan_group_id }) => selling_plan_group_id === sellingPlanGroup.id
      )
    }))
  };
}

/**
 * Retrieves the assigned experiment variant based on the provided experiment settings and session ID.
 *
 * @param {object} experimentSettings - The experiment settings object containing the public ID and variants.
 * @param {string} sessionId - The unique session ID used to determine the variant assignment.
 * @return {object} An object containing the assigned variant and its index.
 */
export function getAssignedExperimentVariant(experimentSettings, sessionId) {
  const experimentPublicId = experimentSettings?.public_id;

  if (!experimentPublicId) {
    return null;
  }
  const variants = experimentSettings.variants;
  const index = getVariantIx(`${experimentPublicId}|${sessionId}`, variants);
  const variant = variants[index];

  return { ...variant, index };
}

/**
 * A middleware function that handles experiment-related actions.
 *
 *
 * @param {object} store - The Redux store object.
 * @return {function} A function that takes the next middleware function and an action as arguments.
 */
export function experimentsMiddleware(store) {
  const [waitForReady, resolveReady] = waitFor();

  let variant, experimentSettings;

  return next => async action => {
    if (action.type === READY) {
      resolveReady();
    } else if (action.type === RECEIVE_MERCHANT_SETTINGS) {
      await waitForReady;
      experimentSettings = action.payload.experiments;

      const { sessionId } = store.getState();

      variant = getAssignedExperimentVariant(experimentSettings, sessionId);

      if (variant) {
        store.dispatch({
          type: SET_EXPERIMENT_VARIANT,
          payload: variant
        });
      }
    } else if (action.type === REQUEST_OFFER) {
      await waitForReady;
      if (variant) {
        action.payload.searchParams = {
          ...action.payload.searchParams,
          variant: variant.public_id
        };
      }
    } else if (action.type === SETUP_PRODUCT) {
      await waitForReady;
      const newProduct = resolveShopifySetupProductWhenExperiment(variant, action.payload.product, experimentSettings);
      if (newProduct) {
        return next({
          type: SETUP_PRODUCT,
          payload: {
            ...action.payload,
            experiments: true,
            originalPayload: action.payload,
            product: newProduct
          }
        });
      }
    }

    return next(action);
  };
}
