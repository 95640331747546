import { html, css } from 'lit-element';
import {
  makeProductFrequencyOptedInSelector,
  makeOptedinSelector,
  getFallbackValue,
  templatesSelector,
  makeProductSpecificDefaultFrequencySelector,
  makeProductFrequenciesSelector,
  makeProductFrequencyOptionsSelector,
  makeProductDefaultFrequencySelector
} from '../core/selectors';
import { connect } from '../core/connect';
import { subscribed, defaultFrequency } from '../core/props';
import { parseFrequency, parseFrequenciesList } from '../core/api';
import { withProduct } from '../core/resolveProperties';
import { TemplateElement } from '../core/base';

export const frequencyText = (frequency, initial) => {
  const { every, every_period: period } = parseFrequency(frequency);
  return every && period
    ? html`
        ${every}
        <og-text key="frequencyPeriods" variant="${period}" pluralize="${every}"></og-text>
        ${initial && initial === frequency
          ? html`
              <og-text key="defaultFrequencyCopy"></og-text>
            `
          : ''}
      `
    : frequency;
};

export class FrequencyStatus extends withProduct(TemplateElement) {
  static get properties() {
    return {
      ...super.properties,
      disabled: { type: Boolean },
      subscribed,
      frequency: { type: String },
      defaultFrequency,
      productDefaultFrequency: { type: String },
      config: { type: Object },
      frequencies: {
        converter: {
          fromAttribute: parseFrequenciesList
        }
      }
    };
  }

  static get styles() {
    return css`
      :host[hidden] {
        display: none;
      }
      :host {
        display: inline-block;
      }
    `;
  }

  constructor() {
    super();
    this.frequencies = [];
  }

  render() {
    const frequency = this.frequency || this.defaultFrequency;
    return html`
      <span>
        ${(this.subscribed &&
          html`
            <slot name="subscribed">${frequencyText(frequency)}</slot>
          `) ||
        ''}
        ${(!this.subscribed &&
          html`
            <slot name="not-subscribed"></slot>
          `) ||
        ''}
        ${(this.subscribed &&
          this.defaultFrequency &&
          this.defaultFrequency !== this.frequency &&
          html`
            <slot name="frequency-mismatch"></slot>
          `) ||
        ''}
      </span>
    `;
  }
}

export const mapStateToProps = (state, ownProps) => ({
  subscribed: makeOptedinSelector(ownProps.product)(state),
  frequency: makeProductFrequencyOptedInSelector(ownProps.product)(state),
  productDefaultFrequency: makeProductSpecificDefaultFrequencySelector((ownProps.product || {}).id)(state),
  frequencies:
    // it's unclear whether the getFallbackValue result is ever used here, but leaving for backwards compatibility
    makeProductFrequencyOptionsSelector(ownProps.product?.id)(state) || getFallbackValue(ownProps, 'frequencies'),
  defaultFrequency:
    makeProductDefaultFrequencySelector(ownProps.product?.id)(state) || getFallbackValue(ownProps, 'defaultFrequency'),
  ...templatesSelector(state, ownProps),
  productFrequencies: makeProductFrequenciesSelector(ownProps.product)(state)
});

export const ConnectedFrequencyStatus = connect(mapStateToProps)(FrequencyStatus);

export default ConnectedFrequencyStatus;
