import { html, css } from 'lit-element';
import { connect } from '../core/connect';
import {
  makeProductPrepaidShipmentOptionsSelector,
  makeProductPrepaidShipmentsOptedInSelector,
  makePrepaidShipmentsSelectedSelector
} from '../core/selectors';
import { productChangePrepaidShipments } from '../core/actions';
import { PrepaidStatus } from './PrepaidStatus';

export class PrepaidSelect extends PrepaidStatus {
  constructor() {
    super();
    this.options = [];
    this.text = 'shipments';
  }

  static get properties() {
    return {
      ...super.properties,
      text: { type: String }
    };
  }

  static get styles() {
    return css`
      og-select {
        display: inline-block;
        cursor: pointer;
        background-color: var(--og-select-bg-color, #fff);
        border: var(--og-select-border, 1px solid #aaa);
        border-width: var(--og-select-border-width, 1px);
        box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
        z-index: 1;
      }
    `;
  }

  render() {
    if (this.options.length === 0) {
      return html``;
    }

    const displayOptions = this.options.map(value => ({
      value: value,
      text: `${value} ${this.text}`
    }));

    return html`
      ${this.options.length > 1
        ? html`
            <og-select
              .options=${displayOptions}
              .selected=${this.selectedNumberOfShipments}
              .onChange="${e => this.handleSelect(e)}"
            ></og-select>
          `
        : html`
            <span>${displayOptions[0].text}</span>
          `}
      <slot name="append"></slot>
    `;
  }
}

const mapStateToProps = (state, ownProps) => ({
  options: makeProductPrepaidShipmentOptionsSelector(ownProps.product.id)(state),
  shipmentsOptedIn: makeProductPrepaidShipmentsOptedInSelector(ownProps.product)(state),
  prepaidShipmentsSelected: makePrepaidShipmentsSelectedSelector(ownProps.product)(state)
});

const ConnectedPrepaidSelect = connect(mapStateToProps, { productChangePrepaidShipments })(PrepaidSelect);

export { ConnectedPrepaidSelect };
