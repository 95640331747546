import { isFrequencyValid } from './api';
/**
 * {"id": "<id>", "components": ["<#1>","<#2>",...,"<#n>"] }
 * {"id": "<id>"}
 */
export const product = {
  type: Object,
  converter: {
    toAttribute(value) {
      return value == null ? value : JSON.stringify(value);
    },
    fromAttribute(value) {
      return value && value.match(/[{[]/) ? JSON.parse(value) : { id: value };
    }
  }
};
export const defaultFrequency = {
  type: String,
  attribute: 'default-frequency',
  converter: {
    fromAttribute(value) {
      return value && isFrequencyValid(value) ? value : null;
    }
  }
};
export const subscribed = { type: Boolean, attribute: true, reflect: true };
export const auth = { type: Object, attribute: false };
export default { product, defaultFrequency };
